import React from "react";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import VictorBot from "components/VictorBot";

const victorbotPage = () => {
  return (
    <Page>
      <div className="page__header">
        <h1>Ask your question</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <VictorBot />
      </div>
    </Page>
  );
};

export default victorbotPage;
