import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Link, NavLink, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { UseOrganizationContext } from "contexts";
import Logo from "./UI/Logo";
import PrivateNavLink from "./PrivateNavLink";
import MenuItem from "./MenuItem";
import {
  FaIdCard,
  FaHome,
  FaUsersCog,
  FaGripHorizontal,
  FaRegCalendarAlt,
  FaUserPlus,
  FaRegComments,
  FaTachometerAlt,
  FaCity,
  FaRegBell,
  FaComment,
  FaRegPaperPlane,
  FaFileInvoice,
} from "react-icons/fa";
import Button from "./UI/Buttons/Button";
import ToggleButton from "./UI/Buttons/ToggleButton";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  .side_menu__primary {
    // overflow-y: auto;
  }

  & .sub_menu {
    padding-left: 3.5rem;
  }

  .logo__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4.6rem;
    margin-left: 2.6rem;
  }

  .toggle_navigation {
    display: flex;
    justify-content: center;
  }

  .logout-text {
    cursor: pointer;
  }

  .company-name {
    cursor: initial;
    font-size: 2.4rem;
    margin-left: 1.2rem;
    color: #f2f2f2;
    font-family: "Montserrat", sans-serif;
  }

  ul {
    width: 100%;
    margin-bottom: 2.4rem;
    &.side_menu__secondary {
      align-self: flex-end;
      justify-self: flex-end;
      margin-bottom: 5rem;
    }
  }
`;

export default function Navigation1() {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  const { given_name, family_name } = account?.idTokenClaims;
  const { name, logoUrl } = UseOrganizationContext();
  const [followerNavigation, setFollowerNavigation] = useState(true);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(async () => {
    if (name) {
      const navigationLS = JSON.parse(localStorage.getItem("navigation"));
      if (navigationLS == null) {
        if (name) {
          switchMode(false);
        } else {
          switchMode(true);
        }
      } else {
        switchMode(navigationLS);
      }
    }
  }, [name]);

  const LogoutHandler = () => {
    try {
      // console.log("Trying to logout")
      const logoutResponse = instance.logout();
    } catch (err) {
      console.log(err);
    }
  };

  const switchMode = async (mode) => {
    setFollowerNavigation(mode);
    localStorage.setItem("navigation", JSON.stringify(mode));
  };

  const switchModeWithRedirect = async (mode) => {
    switchMode(mode);
    if (!mode) {
      history.push("/dashboard");
    } else {
      history.push("dashboard-followers");
    }
  };

  return (
    <Container>
      <div className="side_menu__primary">
        <div className="logo__container">
          <Logo src={logoUrl} />
          <div className="company-name">{name}</div>
        </div>
        {name && (
          <div className="toggle_navigation">
            <ToggleButton on={followerNavigation} label1="COMPANY" label2="INVESTOR" onSwitchOn={() => switchModeWithRedirect(true)} onSwitchOff={() => switchModeWithRedirect(false)} />
          </div>
        )}
        {!followerNavigation ? (
          <>
            <ul>
              <li>
                <NavLink activeClassName="active" to="/dashboard">
                  <FaHome />
                  dashboard
                </NavLink>
              </li>
              <li className="multi-level">
                <PrivateNavLink to="/profile" roles={["Organization.Edit"]} label="Profile" icon={FaCity} />
              </li>
              <li>
                <PrivateNavLink to="/library" roles={["OrganizationUsers.Read"]} icon={FaFileInvoice} label="Library" />
              </li>
              <li>
                <PrivateNavLink to="/users" roles={["OrganizationUsers.Read"]} icon={FaUsersCog} label="Users" />
              </li>
              <li className="multi-level">
                <PrivateNavLink to="/productmanager" roles={["Products.Read"]} label="Shares" icon={FaGripHorizontal} />
              </li>
              <li className="multi-level">
                <PrivateNavLink to="/events" roles={["Events.Read"]} label="Actions" icon={FaRegCalendarAlt} />
              </li>
              <li className="multi-level">
                <MenuItem icon={FaUserPlus} label="Onboarding" roles={["InvestorInsights.Read"]} showCollapse>
                  <ul className="sub_menu">
                    <PrivateNavLink to="/investor-insights/loader" roles={["InvestorInsights.Read"]} label="Upload" />
                    <PrivateNavLink to="/investor-insights/shareholders" roles={["InvestorInsights.Read"]} label="Shareholders" />
                  </ul>
                </MenuItem>
              </li>
              <li className="multi-level">
                <MenuItem icon={FaRegComments} label="Communication" showCollapse>
                  <ul className="sub_menu">
                    <PrivateNavLink to="/followers" roles={["Followers.Read"]} label="Followers" />
                    <PrivateNavLink to="/communicationgroups" roles={["CommunicationGroups.Read"]} label="Communication Groups" />
                    <PrivateNavLink to="/communication" roles={["Communication.Read"]} label="Q/A" />
                    <PrivateNavLink to="/posts" roles={["Posts.Read"]} label="Posts" />
                    <PrivateNavLink to="/survey" roles={["Posts.Read"]} label="Survey" />
                    <PrivateNavLink to="/mailing" roles={["Posts.Read"]} label="Mail Campaigns" />
                  </ul>
                </MenuItem>
              </li>
              <li className="multi-level">
                <MenuItem icon={FaTachometerAlt} label="Analytics" roles={["Analytics.Read"]} showCollapse>
                  <ul className="sub_menu">
                    <PrivateNavLink to="/analytics" roles={["Analytics.Read"]} label="Shareholders" />
                    <PrivateNavLink to="/analytics-communication" roles={["Analytics.Read"]} label="Communication" />
                  </ul>
                </MenuItem>
              </li>
            </ul>
            <ul>
              <li className="multi-level">
                <PrivateNavLink to="/victorbot" roles={["Organization.Edit"]} label="Victorbot"  />
              </li>
            </ul>
          </>
        ) : (
          <ul>
            <li>
              <NavLink activeClassName="active" to="/dashboard-followers">
                <FaHome />
                {t("dashboard")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/organizations">
                <FaCity />
                {t("companies")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/products">
                <FaGripHorizontal />
                {t("shares")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/followed-events">
                <FaRegCalendarAlt />
                {t("calendar")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/my-questions">
                <FaRegComments />
                {t("qa")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/notifications">
                <FaRegBell />
                {t("notifications")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/my-posts">
                <FaRegPaperPlane />
                {t("posts")}
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </Container>
  );
}
