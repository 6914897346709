import React from "react";
import {Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard.js";
import Events from "../pages/events";
import Products from "../pages/products";
import Profile from "../pages/profile/profile.js";
import Organization from "../pages/profile";
import Users from "../pages/users/users.js";
import Followers from "../pages/followers.js";
import CommunicationGroups from "../pages/communication-groups/communication-groups";
import Support from "../pages/support/support.js";
import InvestorInsights from "../pages/loader/shareholders"
import Communication from "pages/communication/communication.js";
import Posts from "pages/communication/posts.js";
import Login from "../pages/login.js";
import Loader from "../pages/loader/loader"
import Registration from "pages/users/registration.js";
import SupportSuccess from "pages/support/support-success.js";
import CommunicationGroupUserGrid from "pages/communication-groups/Components/CommunicationGroupUserGrid.js";
import PrivateRoute from "components/PrivateRoute.js";
import Unauthorized from "pages/Unauthorized.js";
import ProfileLinks from "../pages/profile/profile-links.js";
import ProfileTranslations from "../pages/profile/translations.js";
import ProfileSectionTranslations from "../pages/profile/profile-section-translations.js";
import User from "../pages/user/index.js";
import Analytics from "pages/analytics/Analytics.js";
import AnalyticsComumunication from "pages/analytics/AnalyticsCommunication.js";
import MyQuestions from "pages/communication/my-questions.js";
import DashboardFollowers from "pages/dashboardFollowers.js";
import { UseOrganizationContext } from "contexts/index.js";
import Notifications from "pages/notifications/notifications.js";
import ViewQuestion from "pages/communication/view-question.js";
import AddPost from "pages/communication/add-post.js";
import MyPosts from "pages/communication/my-posts.js";
import Library from "pages/library";
import Survey from "pages/survey.js";
import MailingCampaign from "pages/mailing.js";
import victorbotPage from "pages/victorbotPage.js";

export const LoginRoutes = () => {
  return (
    <Switch>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
};

export const AppRoutes = () => {
  const organizationId = UseOrganizationContext()?.id;

  return (
    <Switch>
      <Route exact path="/">
        { organizationId ? <Dashboard /> : <DashboardFollowers/> }
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>  
      <Route exact path="/dashboard-followers">
        <DashboardFollowers />
      </Route>       
      <Route exact path="/registration/:code">
        <Registration />
      </Route>
      <Route exact path="/support">
        <Support />
      </Route>
      <Route exact path="/support-success">
        <SupportSuccess />
      </Route>
      <Route exact path="/unauthorized">
        <Unauthorized />
      </Route>
      <Route exact path="/user">
        <User.User />
      </Route>
      <Route exact path="/user/permissions">
        <User.ViewPermissions />
      </Route>
      <Route exact path="/organizations">
        <Organization.FollowedOrganizations/>
      </Route>
      <Route exact path="/organizations/discover">
        <Organization.Organizations/>
      </Route>
      <Route exact path="/organizations/view/:id">
        <Organization.ViewFollowedOrganization/>
      </Route>
      <Route exact path="/organizations/view/:id/documents">
        <Organization.ViewOrganizationDocumentsLinks/>
      </Route>
      <Route exact path="/organizations/view/:id/events">
        <Organization.FollowedOrganizationEvents/>
      </Route>
      <Route exact path="/organizations/view/:id/news">
        <Organization.FollowedOrganizationNews/>
      </Route>
      <Route exact path="/organizations/view/:id/events-calendar">
        <Organization.FollowedOrganizationEventsCalendar/>
      </Route>
      <Route exact path="/organizations/view/:id/products">
        <Organization.FollowedOrganizationProducts/>
      </Route>
      <Route exact path="/organizations/view/:organizationId/products/:productId">
        <Organization.ViewFollowedOrganizationProduct/>
      </Route>
      <Route exact path="/organizations/view/:organizationId/events/:eventId">
        <Organization.ViewFollowedOrganizationEvent/>
      </Route>      
      <Route exact path="/products/discover">
        <Products.Products />
      </Route>
      <Route exact path="/products">
        <Products.FollowedProducts />
      </Route>
      <Route exact path="/products/view/:id">
        <Products.ViewFollowedProduct />
      </Route>
      <Route exact path="/followed-events">
        <Events.FollowedEvents />
      </Route>
      <Route exact path="/followed-events/calendar">
        <Events.FollowedEventsCalendar />
      </Route>
      <Route exact path="/followed-events/view/:id">
        <Events.ViewFollowedEvents />
      </Route>
      <Route exact path="/followed-events/view/:id/documents">
        <Events.ViewDocumentsAndLinks />
      </Route>
      <Route exact path="/my-questions">
        <MyQuestions />
      </Route>
      <Route exact path="/my-questions/view/:id">
        <ViewQuestion />
      </Route>
      <Route exact path="/notifications">
        <Notifications />
      </Route>
      <Route exact path="/my-posts">
        <MyPosts/>
      </Route>
      
      <PrivateRoute exact path="/events" roles={["Events.Read"]} component={Events.Events}/>   
      <PrivateRoute exact path="/events/edit/:id" roles={["Events.Create"]} component={Events.EditEvent}/>   
      <PrivateRoute exact path="/events/edit/:id/translations" roles={["Events.Create"]} component={Events.Translations}/>    
      <PrivateRoute exact path="/events/edit/:id/documents" roles={["Events.Create"]} component={Events.DocumentsAndLinks}/>   
      <PrivateRoute exact path="/events/edit/:id/eventsections" roles={["Events.Create"]} component={Events.EventSections}/>   
      <PrivateRoute exact path="/events/edit/:eventId/eventsections/:id/translations" roles={["Events.Create"]} component={Events.EventSectionTranslations}/>  
      <PrivateRoute exact path="/events/edit/:id/related" roles={["Events.Create"]} component={Events.RelatedEvents}/>    
      <PrivateRoute exact path="/events/add" roles={["Events.Create"]} component={Events.AddEvent}/>  
      <PrivateRoute exact path="/productmanager" roles={["Products.Read"]} component={Products.ProductManager}/>
      <PrivateRoute exact path="/productmanager/edit/:id" roles={["Products.Read"]} component={Products.EditProduct}/>
      <PrivateRoute exact path="/productmanager/add" roles={["Products.Read"]} component={Products.AddProduct}/>
      <PrivateRoute exact path="/productmanager/edit/:id/translations" roles={["Products.Create"]} component={Products.Translations}/>    
      <PrivateRoute exact path="/profile" roles={["Organization.Read"]} component={Profile}/>
      <PrivateRoute exact path="/profile/links" roles={["Organization.Edit"]} component={ProfileLinks}/>  
      <PrivateRoute exact path="/profile/translations" roles={["Organization.Edit"]} component={ProfileTranslations}/>  
      <PrivateRoute exact path="/profile/sections/:id/translations" roles={["Organization.Edit"]} component={ProfileSectionTranslations}/> 
      <PrivateRoute exact path="/users" roles={["OrganizationUsers.Read"]} component={Users}/> 
      <PrivateRoute exact path="/investor-insights/shareholders" roles={["InvestorInsights.Read"]} component={InvestorInsights}/>
      <PrivateRoute exact path="/investor-insights/loader" roles={["InvestorInsights.Read"]} component={Loader}/>
      <PrivateRoute exact path="/followers" roles={["Followers.Read"]} component={Followers}/>
      <PrivateRoute exact path="/communicationgroups" roles={["CommunicationGroups.Read"]} component={CommunicationGroups}/>
      <PrivateRoute exact path="/communicationgroups/:id/users" roles={["CommunicationGroups.Create"]} component={CommunicationGroupUserGrid}/>
      <PrivateRoute exact path="/communication" roles={["Communication.Read"]} component={Communication}/>
      <PrivateRoute exact path="/posts" roles={["Posts.Read"]} component={Posts}/>
      <PrivateRoute exact path="/posts/add" roles={["Posts.Create"]} component={AddPost}/>
      <PrivateRoute exact path="/analytics" roles={["Analytics.Read"]} component={Analytics}/>
      <PrivateRoute exact path="/analytics-communication" roles={["Analytics.Read"]} component={AnalyticsComumunication}/>
      <PrivateRoute exact path="/library" roles={["Analytics.Read"]} component={Library}/>
      <PrivateRoute exact path="/survey" roles={["Posts.Read"]} component={Survey}/>
      <PrivateRoute exact path="/mailing" roles={["Posts.Read"]} component={MailingCampaign}/>
      <PrivateRoute exact path="/victorbot" roles={["Posts.Read"]} component={victorbotPage}/>
     
    </Switch>
  );
};
