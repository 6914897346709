import { getAxiosClient } from "../axios";

export const sendMessage = async (message) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post(`/chat`, { message })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};
