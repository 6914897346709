import React, { useState } from "react";
import styled from "styled-components";
import { sendMessage } from "api/services/chatbot";

// Styled Components
const Container = styled.div`
  width: 90%;
  margin: 3rem auto;
  background: #1e1e2f;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  color: #fff;
  font-family: "Arial", sans-serif;
  height: 80vh;
  overflow: auto;
  position: relative;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  max-height: 87%;
  overflow: auto;
`;

const Message = styled.div`
  background: ${(props) => (props.isUser ? "#ff6b6b" : "#252544")};
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  max-width: 80%;
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
`;

const InputArea = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 0;
  width: 90%;
  margin: 2rem 2.5%;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #3e3e50;
  border-radius: 8px;
  background: #252544;
  color: #fff;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #ff6b6b;
  }
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background: #ff6b6b;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: #e55a5a;
  }
`;

// App Component
const VictorBot = () => {
  const [messages, setMessages] = useState([
    { text: "Hello, my name is Victor! How can I help you today?", isUser: false },
    { text: "hello, can you help me", isUser: true },
    { text: "Of course! How can I assist you today?", isUser: false },
    { text: "can you show me the euronav latest Quarterly report?", isUser: true },
    {
      text: "The latest quarterly report for Euronav can be found in their earnings release, which includes links to additional information on their investor website and also mentions the annual report. You can access the relevant documents and details through the following links:\n[1] earnings-release-q1-2023_.pdf\nThe report provides an overview of the company’s performance and financial data for the relevant quarter.",
      isUser: false,
    },
  ]);

  const [inputValue, setInputValue] = useState("");

  const handleSend = async () => {
    const generateId = () => new Date().getTime();
    if (inputValue.trim()) {
      setMessages([...messages, { text: inputValue.trim(), isUser: true }]);
      setInputValue("");

      try {
        const response = await sendMessage(inputValue.trim());

        const botMessage = {
          id: generateId(),
          text: response.message,
          isUser: false,
          citations: response.citations,
        };

        setMessages((prev) => [...prev, botMessage]);
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
          id: generateId(),
          text: "Sorry, something went wrong. Please try again later.",
          isUser: false,
        };
        setMessages((prev) => [...prev, errorMessage]);
      } finally {
        //setLoading(false)
      }
    }
  };

  return (
    <Container>
      <Header>Talk with Victor</Header>
      <ChatWindow>
        {messages.map((msg, index) => (
          <Message key={index} isUser={msg.isUser}>
            {msg.text}
          </Message>
        ))}
      </ChatWindow>
      <InputArea>
        <Input type="text" placeholder="Type a message..." value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyDown={(e) => e.key === "Enter" && handleSend()} />
        <SendButton onClick={handleSend}>Send</SendButton>
      </InputArea>
    </Container>
  );
};

export default VictorBot;
